/* ========= ACTIVE COLORS ========= */
:root {
	/* SITE */
	--color-site: var(--palette-site);

	/* PRIMARY */
	--color-primary: var(--palette-primary-blue);
	--color-primary-dark: var(--palette-primary-blue-dark);
	--color-primary-bright: var(--palette-primary-blue-bright);
	--color-primary-screen: var(--palette-primary-blue-screen);
	--color-primary-shadow: var(--palette-primary-blue-shadow);

	/* ACCENT */
	--color-accent: var(--palette-accent-salmon);
	--color-accent-screen: var(--palette-accent-salmon-screen);
	--color-accent-shadow: var(--palette-accent-salmon-shadow);

	/* STATUS */
	--color-status-student-invited: var(--palette-tertiary-gold);
	--color-status-student-progressing: var(--palette-tertiary-yellow);
	--color-status-student-complete: var(--palette-tertiary-green);
	--color-status-student-overdue: var(--palette-tertiary-pink);
	--color-status-student-exempt: var(--palette-tertiary-transparent);
	--color-status-student-archived: var(--palette-tertiary-transparent);
	--color-status-student-disciplined: var(--palette-tertiary-fuchsia);

	--color-status-org-pending: var(--palette-tertiary-pink);
	--color-status-org-active: var(--palette-tertiary-green);
	--color-status-org-archived: var(--palette-tertiary-transparent);

	--color-status-admin-invited: var(--palette-tertiary-yellow);
	--color-status-admin-active: var(--palette-tertiary-green);
	--color-status-admin-archived: var(--palette-tertiary-transparent);
}

/* ========= PALETTE ========= */

/* SITE */
:root {
	--palette-site: rgba(255, 252, 229, 1);
}

/* PRIMARIES */
:root {
	--palette-primary-violet: rgba(83, 7, 181, 1);
	--palette-primary-violet-dark: rgba(54, 7, 114, 1);
	--palette-primary-violet-bright: rgba(131, 34, 255, 1);
	--palette-primary-violet-screen: rgba(83, 7, 181, 0.25);
	--palette-primary-violet-shadow: rgba(131, 34, 255, 0.2);

	--palette-primary-teal: rgba(0, 76, 81, 1);
	--palette-primary-teal-dark: rgba(1, 60, 64, 1);
	--palette-primary-teal-bright: rgba(6, 129, 137, 1);
	--palette-primary-teal-screen: rgba(0, 76, 81, 0.25);
	--palette-primary-teal-shadow: rgba(6, 129, 137, 0.3);

	--palette-primary-green: rgba(0, 90, 34, 1);
	--palette-primary-green-dark: rgba(0, 63, 24, 1);
	--palette-primary-green-bright: rgba(18, 134, 62, 1);
	--palette-primary-green-screen: rgba(0, 90, 34, 0.25);
	--palette-primary-green-shadow: rgba(18, 134, 62, 0.3);

	--palette-primary-red: rgba(138, 3, 60, 1);
	--palette-primary-red-dark: rgba(105, 0, 44, 1);
	--palette-primary-red-bright: rgba(232, 14, 14, 1);
	--palette-primary-red-screen: rgba(138, 3, 60, 0.25);
	--palette-primary-red-shadow: rgba(232, 14, 14, 0.2);

	--palette-primary-fuchsia: rgba(129, 0, 124, 1);
	--palette-primary-fuchsia-dark: rgba(87, 0, 84, 1);
	--palette-primary-fuchsia-bright: rgba(190, 2, 183, 1);
	--palette-primary-fuchsia-screen: rgba(129, 0, 124, 0.25);
	--palette-primary-fuchsia-shadow: rgba(190, 2, 183, 0.25);

	--palette-primary-gold: rgba(97, 81, 0, 1);
	--palette-primary-gold-dark: rgba(70, 59, 1, 1);
	--palette-primary-gold-bright: rgba(136, 115, 0, 1);
	--palette-primary-gold-screen: rgba(97, 81, 0, 0.25);
	--palette-primary-gold-shadow: rgba(136, 115, 0, 0.3);

	--palette-primary-blue: rgb(41, 6, 136);
	--palette-primary-blue-dark: rgba(31, 0, 119, 1);
	--palette-primary-blue-bright: rgba(42, 42, 255, 1);
	--palette-primary-blue-screen: rgb(153, 133, 213);
	--palette-primary-blue-shadow: rgba(89, 0, 235, 0.2);

	--palette-primary-slategray: rgba(37, 46, 65, 1);
	--palette-primary-slategray-dark: rgba(21, 26, 35, 1);
	--palette-primary-slategray-bright: rgba(70, 87, 125, 1);
	--palette-primary-slategray-screen: rgba(37, 46, 65, 0.25);
	--palette-primary-slategray-shadow: rgba(70, 87, 125, 0.2);

	--palette-primary-steelblue: rgba(21, 49, 91, 1);
	--palette-primary-steelblue-dark: rgba(11, 24, 44, 1);
	--palette-primary-steelblue-bright: rgba(35, 80, 148, 1);
	--palette-primary-steelblue-screen: rgba(21, 49, 91, 0.25);
	--palette-primary-steelblue-shadow: rgba(35, 80, 148, 0.3);
}

/* ACCENTS */
:root {
	--palette-accent-salmon: rgba(255, 163, 123, 1);
	--palette-accent-salmon-screen: rgba(255, 163, 123, 0.25);
	--palette-accent-salmon-shadow: rgba(255, 163, 123, 0.35);

	--palette-accent-gray: rgba(201, 205, 216, 1);
	--palette-accent-gray-screen: rgba(201, 205, 216, 0.2);
	--palette-accent-gray-shadow: rgba(0, 33, 102, 0.2);

	--palette-accent-violet: rgba(218, 188, 255, 1);
	--palette-accent-violet-screen: rgba(218, 188, 255, 0.3);
	--palette-accent-violet-shadow: rgba(218, 188, 255, 0.8);

	--palette-accent-blue: rgba(160, 215, 255, 1);
	--palette-accent-blue-screen: rgba(160, 215, 255, 0.3);
	--palette-accent-blue-shadow: rgba(160, 215, 255, 0.8);

	--palette-accent-turquoise: rgba(53, 230, 254, 1);
	--palette-accent-turquoise-screen: rgba(53, 230, 254, 0.2);
	--palette-accent-turquoise-shadow: rgba(53, 230, 254, 0.5);

	--palette-accent-green: rgba(47, 248, 154, 1);
	--palette-accent-green-screen: rgba(47, 248, 154, 0.2);
	--palette-accent-green-shadow: rgba(47, 248, 154, 0.5);

	--palette-accent-gold: rgba(210, 198, 131, 1);
	--palette-accent-gold-screen: rgba(210, 198, 131, 0.3);
	--palette-accent-gold-shadow: rgba(194, 177, 86, 0.5);

	--palette-accent-yellow: rgba(252, 255, 115, 1);
	--palette-accent-yellow-screen: rgba(252, 255, 115, 0.5);
	--palette-accent-yellow-shadow: rgba(252, 255, 115, 1);

	--palette-accent-orange: rgba(255, 197, 85, 1);
	--palette-accent-orange-screen: rgba(255, 197, 85, 0.25);
	--palette-accent-orange-shadow: rgba(255, 197, 85, 0.5);

	--palette-accent-pink: rgba(253, 193, 237, 1);
	--palette-accent-pink-screen: rgba(253, 193, 237, 0.3);
	--palette-accent-pink-shadow: rgba(253, 193, 237, 0.7);
}

/* TERTIARY */
:root {
	--palette-tertiary-transparent: rgba(1, 1, 1, 0);
	--palette-tertiary-gold: rgba(255, 217, 121, 1);
	--palette-tertiary-yellow: rgba(252, 255, 115, 1);
	--palette-tertiary-green: rgba(47, 248, 154, 1);
	--palette-tertiary-pink: rgba(253, 193, 237, 1);
	--palette-tertiary-fuchsia: rgba(255, 140, 224, 1);
}

/* Thursday Night */
:root {
	--thursday-night-text-dark: #3e394a;
	--thursday-night-text-light: #dddddd;
	--thursday-night-friend-message-bg: #3e394a;
	--thursday-night-you-message-bg: #2a2fff;
	--thursday-night-message-screen-bg: #120e1e;
}

/* WWW */
:root {
	/* PRIMARY COLORS */

	/* SLATE */
	--primary-slate: rgba(37, 46, 65, 1);
	--primary-slate-dark: rgba(21, 26, 35, 1);
	--primary-slate-bright: rgba(70, 87, 125, 1);
	--primary-slate-screen: rgba(37, 46, 65, 0.25);
	--primary-slate-shadow: rgba(70, 87, 125, 0.2);

	/* VIOLET */
	--primary-violet: rgba(83, 7, 181, 1);
	--primary-violet-dark: rgba(54, 7, 114, 1);
	--primary-violet-bright: rgba(131, 34, 255, 1);
	--primary-violet-screen: rgba(83, 7, 181, 0.25);
	--primary-violet-shadow: rgba(131, 34, 255, 0.2);

	/* DARK BLUE */
	--primary-dark-blue: rgba(52, 10, 171, 1);
	--primary-dark-blue-dark: rgba(31, 0, 119, 1);
	--primary-dark-blue-bright: rgba(42, 42, 255, 1);
	--primary-dark-blue-screen: rgba(52, 10, 171, 0.25);
	--primary-dark-blue-shadow: rgba(89, 0, 235, 0.2);

	/* STEEL BLUE -- NEED OTHER SHADES */
	--primary-reflex-blue: rgba(21, 49, 91, 1);
	--primary-reflex-blue-dark: rgba(11, 24, 44, 1);
	--primary-reflex-blue-bright: rgba(35, 80, 148, 1);
	--primary-reflex-blue-screen: rgba(20, 0, 255, 0.25);
	--primary-reflex-blue-shadow: rgba(35, 80, 148, 0.3);

	/* TEAL */
	--primary-teal: rgba(0, 76, 81, 1);
	--primary-teal-dark: rgba(1, 60, 64, 1);
	--primary-teal-bright: rgba(6, 129, 137, 1);
	--primary-teal-screen: rgba(0, 76, 81, 0.25);
	--primary-teal-shadow: rgba(6, 129, 137, 0.3);

	/* GREEN */
	--primary-green: rgba(0, 90, 34, 1);
	--primary-green-dark: rgba(0, 63, 24, 1);
	--primary-green-bright: rgba(18, 134, 62, 1);
	--primary-green-screen: rgba(0, 90, 34, 0.25);
	--primary-green-shadow: rgba(18, 134, 62, 0.3);

	/* GOLD */
	--primary-gold: rgba(97, 81, 0, 1);
	--primary-gold-dark: rgba(70, 59, 1, 1);
	--primary-gold-bright: rgba(136, 115, 0, 1);
	--primary-gold-screen: rgba(97, 81, 0, 0.25);
	--primary-gold-shadow: rgba(136, 115, 0, 0.3);

	/* RED */
	--primary-red: rgba(138, 3, 60, 1);
	--primary-red-dark: rgba(105, 0, 44, 1);
	--primary-red-bright: rgba(232, 14, 14, 1);
	--primary-red-screen: rgba(138, 3, 60, 0.25);
	--primary-red-shadow: rgba(232, 14, 14, 0.2);

	/* FUCHSIA */
	--primary-fuchsia: rgba(129, 0, 124, 1);
	--primary-fuchsia-dark: rgba(87, 0, 84, 1);
	--primary-fuchsia-bright: rgba(190, 2, 183, 1);
	--primary-fuchsia-screen: rgba(129, 0, 124, 0.25);
	--primary-fuchsia-shadow: rgba(190, 2, 183, 0.25);

	/* ACCENT COLORS */

	/* GREY */
	--accent-grey: rgba(201, 205, 216, 1);
	--accent-grey-screen: rgba(201, 205, 216, 0.3);
	--accent-grey-shadow: rgba(201, 205, 216, 0.8);

	/* PURPLE */
	--accent-purple: rgba(218, 188, 255, 1);
	--accent-purple-screen: rgba(218, 188, 255, 0.3);
	--accent-purple-shadow: rgba(218, 188, 255, 0.8);

	/* LIGHT BLUE */
	--accent-light-blue: rgba(160, 215, 255, 1);
	--accent-light-blue-screen: rgba(160, 215, 255, 0.3);
	--accent-light-blue-shadow: rgba(160, 215, 255, 0.8);

	/* ELECTRIC BLUE */
	--accent-electric-blue: rgba(53, 230, 254, 1);
	--accent-electric-blue-screen: rgba(53, 230, 254, 0.2);
	--accent-electric-blue-shadow: rgba(53, 230, 254, 0.5);

	/* GREEN */
	--accent-green: rgba(47, 248, 154, 1);
	--accent-green-screen: rgba(47, 248, 154, 0.2);
	--accent-green-shadow: rgba(47, 248, 154, 0.5);

	/* LIME */
	--accent-lime: rgba(164, 211, 103, 1);
	--accent-lime-screen: rgba(164, 211, 103, 0.2);
	--accent-lime-shadow: rgba(164, 211, 103, 0.5);

	/* GOLD */
	--accent-gold: rgba(210, 198, 131, 1);
	--accent-gold-screen: rgba(210, 198, 131, 0.3);
	--accent-gold-shadow: rgba(194, 177, 86, 0.5);

	/* SALMON */
	--accent-salmon: rgba(255, 163, 123, 1);
	--accent-salmon-screen: rgba(255, 163, 123, 0.25);
	--accent-salmon-shadow: rgba(255, 163, 123, 0.35);

	/* YELLOW */
	--accent-yellow: rgba(252, 255, 115, 1);
	--accent-yellow-screen: rgba(252, 255, 115, 0.5);
	--accent-yellow-shadow: rgba(252, 255, 115, 1);

	/* PINK */
	--accent-pink: rgba(253, 193, 237, 1);
	--accent-pink-screen: rgba(253, 193, 237, 0.3);
	--accent-pink-shadow: rgba(253, 193, 237, 0.7);
}
