/* Black */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-Black.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Black.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-BlackItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-BlackItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-BlackItalic.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}

/* Heavy */

/* @font-face {
	font-family: 'Qanelas';
	src: 
		url('/fonts/qanelas/Qanelas-Heavy.woff') format('woff'),
		url('/fonts/qanelas/Qanelas-Heavy.woff') format('woff'),
		url('/fonts/qanelas/Qanelas-Heavy.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
} */

/* @font-face {
	font-family: 'Qanelas';
	src: 
		url('/fonts/qanelas/Qanelas-HeavyItalic.woff') format('woff'),
		url('/fonts/qanelas/Qanelas-HeavyItalic.woff') format('woff'),
		url('/fonts/qanelas/Qanelas-HeavyItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
} */

/* ExtraBold */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-ExtraBold.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-ExtraBold.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-ExtraBold.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-ExtraBoldItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-ExtraBoldItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-ExtraBoldItalic.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

/* Bold */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-Bold.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Bold.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-BoldItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-BoldItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;
}

/* SemiBold */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-SemiBold.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-SemiBold.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-SemiBoldItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-SemiBoldItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-SemiBoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

/* Medium */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-Medium.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Medium.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-MediumItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-MediumItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
}

/* Regular */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-Regular.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Regular.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-RegularItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-RegularItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-RegularItalic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}

/* Light */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-Light.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Light.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-LightItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-LightItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

/* UltraLight */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-UltraLight.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-UltraLight.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-UltraLight.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-UltraLightItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-UltraLightItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-UltraLightItalic.ttf") format("truetype");
	font-weight: 200;
	font-style: italic;
}

/* Thin */

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-Thin.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Thin.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Qanelas";
	src:
		url("/fonts/qanelas/Qanelas-ThinItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-ThinItalic.woff") format("woff"),
		url("/fonts/qanelas/Qanelas-ThinItalic.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}
