body {
	font-family: "Qanelas";
	background: var(--color-site);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

#__next-route-announcer__ {
	display: none;
	user-select: none;
}

.react-confirm-alert-overlay {
	background: none !important;
}

.react-confirm-alert-blur {
	filter: blur(2px) !important;
	-webkit-filter: blur(2px) !important;
	transition: filter ease-in 0.1s;
}

:root {
	--story-header-percent-width: 0%;
}

/* ========== ANIMATIONS ========== */
@keyframes wobble {
	0%,
	30% {
		transform: translateX(0);
		transform-origin: 50% 50%;
	}
	5% {
		transform: translateX(-30px) rotate(-6deg);
	}
	10% {
		transform: translateX(15px) rotate(6deg);
	}
	15% {
		transform: translateX(-15px) rotate(-3.6deg);
	}
	20% {
		transform: translateX(9px) rotate(2.4deg);
	}
	25% {
		transform: translateX(-6px) rotate(-1.2deg);
	}
}

@keyframes slide-in-left {
	0% {
		transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
		transform-origin: 100% 50%;
		/* filter: blur(40px); */
		opacity: 0;
	}
	100% {
		transform: translateX(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		/* filter: blur(0); */
		opacity: 1;
	}
}
@keyframes slide-in-right {
	0% {
		transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		transform-origin: 0 50%;
		/* filter: blur(40px); */
		opacity: 0;
	}
	100% {
		transform: translateX(0) scaleY(1) scaleX(1);
		transform-origin: 50% 50%;
		/* filter: blur(0); */
		opacity: 1;
	}
}
@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(25px);
		transform: translateY(25px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
