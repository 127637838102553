/* Bold */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-Bold.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Bold.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Bold.woff") format("woff");
	font-weight: 700;
	font-stretch: 0% 100%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-BoldItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-BoldItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-BoldItalic.woff") format("woff");
	font-weight: 700;
	font-stretch: 0% 100%;
	font-style: italic;
}

/* Wide Bold */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-WideBold.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideBold.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideBold.woff") format("woff");
	font-weight: 700;
	font-stretch: 101% 125%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-WideBoldItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideBoldItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideBoldItalic.woff") format("woff");
	font-weight: 700;
	font-stretch: 101% 125%;
	font-style: italic;
}

/* Extended Bold */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-ExtendedBold.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedBold.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedBold.woff") format("woff");
	font-weight: 700;
	font-stretch: 126% 150%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-ExtendedBoldItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedBoldItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedBoldItalic.woff") format("woff");
	font-weight: 700;
	font-stretch: 126% 150%;
	font-style: italic;
}

/* Medium */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-Medium.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Medium.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Medium.woff") format("woff");
	font-weight: 500;
	font-stretch: 0% 100%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-MediumItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-MediumItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-MediumItalic.woff") format("woff");
	font-weight: 500;
	font-stretch: 0% 100%;
	font-style: italic;
}

/* Wide Medium */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-WideMedium.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideMedium.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideMedium.woff") format("woff");
	font-weight: 500;
	font-stretch: 101% 125%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-WideMediumItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideMediumItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideMediumItalic.woff") format("woff");
	font-weight: 500;
	font-stretch: 101% 125%;
	font-style: italic;
}

/* Extended Medium */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-ExtendedMedium.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedMedium.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedMedium.woff") format("woff");
	font-weight: 500;
	font-stretch: 126% 150%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-ExtendedMediumItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedMediumItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedMediumItalic.woff") format("woff");
	font-weight: 500;
	font-stretch: 126% 150%;
	font-style: italic;
}

/* Regular */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-Regular.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Regular.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Regular.woff") format("woff");
	font-weight: 400;
	font-stretch: 0% 100%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-RegularItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-RegularItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-RegularItalic.woff") format("woff");
	font-weight: 400;
	font-stretch: 0% 100%;
	font-style: italic;
}

/* Wide Regular */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-WideRegular.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideRegular.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideRegular.woff") format("woff");
	font-weight: 400;
	font-stretch: 101% 125%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-WideRegularItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideRegularItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideRegularItalic.woff") format("woff");
	font-weight: 400;
	font-stretch: 101% 125%;
	font-style: italic;
}

/* Extended Regular */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-ExtendedRegular.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedRegular.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedRegular.woff") format("woff");
	font-weight: 400;
	font-stretch: 126% 150%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-ExtendedRegularItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedRegularItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedRegularItalic.woff") format("woff");
	font-weight: 400;
	font-stretch: 126% 150%;
	font-style: italic;
}

/* Light */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-Light.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Light.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Light.woff") format("woff");
	font-weight: 300;
	font-stretch: 0% 100%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-LightItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-LightItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-LightItalic.woff") format("woff");
	font-weight: 300;
	font-stretch: 0% 100%;
	font-style: italic;
}

/* Wide Light */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-WideLight.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideLight.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideLight.woff") format("woff");
	font-weight: 300;
	font-stretch: 101% 125%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-WideLightItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideLightItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-WideLightItalic.woff") format("woff");
	font-weight: 300;
	font-stretch: 101% 125%;
	font-style: italic;
}

/* Extended Light */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-ExtendedLight.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedLight.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedLight.woff") format("woff");
	font-weight: 300;
	font-stretch: 126% 150%;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-ExtendedLightItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedLightItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-ExtendedLightItalic.woff") format("woff");
	font-weight: 300;
	font-stretch: 126% 150%;
	font-style: italic;
}

/* Hairline */

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-Hairline.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Hairline.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-Hairline.woff") format("woff");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Antipol";
	src:
		url("/fonts/antipol/Antipol-HairlineItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-HairlineItalic.woff2") format("woff2"),
		url("/fonts/antipol/Antipol-HairlineItalic.woff") format("woff");
	font-weight: 100;
	font-style: italic;
}
